import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import auth from "../../config/firebase";
import LoadingIcons from "react-loading-icons";
import { JsonViewer } from "@textea/json-viewer";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import { Parser } from "@json2csv/plainjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import capitalize from "capitalize";
import {
  default as defaultFormatter,
  number as numberFormatter,
  string as stringFormatter,
  stringQuoteOnlyIfNecessary as stringQuoteOnlyIfNecessaryFormatter,
  stringExcel as stringExcelFormatter,
  symbol as symbolFormatter,
  object as objectFormatter,
} from "@json2csv/formatters";
import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";


export default function EnrichLayout() {
  // const [isContact, setIsContact] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [bulkMessage, setBulkMessage] = useState("");

  const [jsonRes, setJson] = useState("");
  const [jsonResBulk, setJsonBulk] = useState("");

  const [enableCSVUpload, setCSVUpload] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [openTab, setOpenTab] = useState("");
  const { color } = "slate";
  const { currentUser } = useAuth();
  const ocean = {
    scheme: "Ocean",
    base00: "#2b303b",
    base01: "#343d46",
    base02: "#4f5b66",
    base03: "#65737e",
    base04: "#a7adba",
    base05: "#c0c5ce",
    base06: "#dfe1e8",
    base07: "#eff1f5",
    base08: "#bf616a",
    base09: "#d08770",
    base0A: "#ebcb8b",
    base0B: "#a3be8c",
    base0C: "#96b5b4",
    base0D: "#8fa1b3",
    base0E: "#b48ead",
    base0F: "#ab7967",
  };

  // const jsonRes = ""
  const [transactionData, setTransactionData] = useState("");
  const [bulkData, setBulkData] = useState("");

  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
  const bulkCsvLink = useRef();
  const navigate = useNavigate();

  var csvStorage = [];

  function ParseProfessionalEmails(aJson) {
    let finalJson = [];
    for (let i = 0; i < aJson.length; i++) {
      if (
        aJson[i].type === "professional" ||
        aJson[i].type === "current_professional"
      ) {
        finalJson.push(aJson[i].address);
      }
    }
    return finalJson;
  }

  function ParseJson(aJson) {
    var first_name = capitalize(
      aJson.data.first_name ? aJson.data.first_name : ""
    );
    var last_name = capitalize(
      aJson.data.last_name ? aJson.data.last_name : ""
    );
    var linkedin_url = aJson.data.linkedin_url;
    var facebook_url = aJson.data.facebook_url;
    var twitter_url = aJson.data.twitter_url;
    var work_email = aJson.data.work_email ? aJson.data.work_email : "";
    var personal_emails = aJson.data.personal_emails
      ? aJson.data.personal_emails.join("; ")
      : "";
    var professional_emails = aJson.data.emails
      ? ParseProfessionalEmails(aJson.data.emails).join("; ")
      : "";
    var mobile_phone = aJson.data.mobile_phone;
    var job_title = capitalize.words(
      aJson.data.job_title ? aJson.data.job_title.replace(",", "") : ""
    );
    var job_company_name = capitalize.words(
      aJson.data.job_company_name
        ? aJson.data.job_company_name.replace(",", "")
        : ""
    );
    var job_company_industry = capitalize.words(
      aJson.data.job_company_industry
        ? aJson.data.job_company_industry.replace(",", "")
        : ""
    );
    var job_company_linkedin_url = aJson.data.job_company_linkedin_url;
    var job_company_location_locality = capitalize.words(
      aJson.data.job_company_location_locality
        ? aJson.data.job_company_location_locality
        : ""
    );
    var job_company_location_region = capitalize.words(
      aJson.data.job_company_location_region
        ? aJson.data.job_company_location_region
        : ""
    );
    var job_company_location_street_address = capitalize.words(
      aJson.data.job_company_location_street_address
        ? aJson.data.job_company_location_street_address.replace(",", "")
        : ""
    );
    var job_company_location_postal_code =
      aJson.data.job_company_location_postal_code;
    var job_company_location_country = capitalize.words(
      aJson.data.job_company_location_country
        ? aJson.data.job_company_location_country
        : ""
    );
    var phone_numbers = aJson.data.phone_numbers
      ? aJson.data.phone_numbers.join("; ")
      : "";

    var singleEntry = {
      first_name: first_name,
      last_name: last_name,
      linkedin_url: linkedin_url,
      facebook_url: facebook_url,
      twitter_url: twitter_url,
      work_email: work_email,
      personal_emails: personal_emails,
      professional_emails: professional_emails,
      mobile_phone: mobile_phone,
      job_title: job_title,
      job_company_name: job_company_name,
      job_company_industry: job_company_industry,
      job_company_linkedin_url: job_company_linkedin_url,
      job_company_location_city: job_company_location_locality,
      job_company_location_state: job_company_location_region,
      job_company_location_street_address: job_company_location_street_address,
      job_company_location_postal_code: job_company_location_postal_code,
      job_company_location_country: job_company_location_country,
      phone_numbers: phone_numbers,
    };
    return singleEntry;
  }

  useEffect(() => {
    const fetchUser = async () => {
      const user = auth.currentUser;
      const token = user && (await user.getIdToken());
      let res = await fetch(process.env.REACT_APP_API_HOST + "/api/user/role", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(tempJson),
      });

      if (res.status === 200) {
        let resJson = await res.json();
        return resJson;
      } else {
        return {};
      }
    };

    (async () => {
      const result = await fetchUser();
      console.log(result)
      if (JSON.stringify(result) === '{}'){

        navigate("/")

      }
      else if ("code" in result && result.code === "ADM"){

              setCSVUpload(true);

      }
      // setCSVUpload("code" in result && result.code == "ADM" ? true : false);
    })();

    setOpenTab(1);
  }, []);

  const getCsv = async () => {
    // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
    csvLink.current.link.click();
  };

  const getCsvBulk = async () => {
    // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
    bulkCsvLink.current.link.click();
  };

  const bulkEnrich = async (rows) => {
    setJsonBulk();

    var recordCounter = 0;
    try {
      console.log("rows are:");
      console.log(rows);
      let tempJson = {
        requests: [],
      };

      for (let i = 0; i < rows.length; i++) {
        let params = {
          params: {
            company: rows[i].company,
            email: rows[i].email,
            first_name: rows[i].first_name,
            last_name: rows[i].last_name,
          },
        };
        tempJson.requests.push(params);
      }

      console.log("temp json:");
      console.log(JSON.stringify(tempJson));
      setSpinner(true);

      const user = auth.currentUser;
      const token = user && (await user.getIdToken());
      let res = await fetch(
        process.env.REACT_APP_API_HOST + "/api/enrichment/bulk",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(tempJson),
        }
      );
      let resJson = await res.json();
      console.log("response from bulk api was: ");
      console.log(JSON.stringify(resJson));
      var finalJson = [];
      if (res.status === 200) {
        // setSpinner(false);
        console.log("res json length is: " + resJson.length);
        try {
          for (let i = 0; i < resJson.length; i++) {
            console.log("res json status is ");
            console.log(resJson[i].status);
            if (resJson[i].status === 200) {
              recordCounter++;
            }
            let singleEntry = ParseJson(resJson[i]);

            finalJson.push(singleEntry);
          }

          const opts = {
            formatters: {
              string: stringFormatter({ quote: "" }),
            },
          };
          const parser = new Parser(opts);
          // const csv = parser.parse(dummyData);
          const csv = parser.parse(finalJson);
          console.log("csv is:");
          console.log(csv);
          setBulkData(csv);
        } catch (err) {
          console.error(err);
        }
        // console.log(res.body);
        toast.info(
          recordCounter.toString() +
            " of " +
            resJson.length.toString() +
            " rows were successfully enriched.",
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setSpinner(false);

        setBulkMessage(
          "Successfully enriched " +
            recordCounter.toString() +
            " of " +
            resJson.length.toString() +
            " rows."
        );
        // console.log(JSON.stringify(resJson));
        setJsonBulk(finalJson);
      } else {
        setSpinner(false);
        setJsonBulk();
        setTransactionData();
        setMessage("No match found.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;
      const token = user && (await user.getIdToken());
      console.log("token is " + token);
      setSpinner(true);
      console.log("api host is ");
      console.log(process.env.REACT_APP_API_HOST);
      let res = await fetch(
        process.env.REACT_APP_API_HOST + "/api/enrichment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            email: email,
            company: company,
          }),
        }
      );
      let resJson = await res.json();
      if (res.status === 200) {
        setSpinner(false);
        try {
          var finalJson = ParseJson(resJson);

          const opts = {
            formatters: {
              string: stringFormatter({ quote: "" }),
            },
          };
          const parser = new Parser(opts);
          // const csv = parser.parse(dummyData);
          const csv = parser.parse(finalJson);
          console.log("csv is:");
          console.log(csv);
          setTransactionData(csv);
          toast.success("Successfully found a match!", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } catch (err) {
          console.error(err);
        }
        // console.log(res.body);
        // setMessage("Match found!");
        // console.log(JSON.stringify(resJson));
        setJson(resJson.data);
      } else {
        setSpinner(false);
        setJson();
        setTransactionData();
        setMessage("No match found.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
      <div className="min-h-screen flex justify-center px-4 sm:px-6 lg:px-8">

      <div className="container mx-auto">
        <div className="flex flex-wrap">
          <div className={(enableCSVUpload ? "block " : "hidden ") + "w-full"}>
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-black dark:text-white dark:bg-slate-800 bg-slate-200"
                      : "text-" + color + "-600 bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  <i className="fas fa-space-shuttle text-base mr-1"></i> Single
                  Lead
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    // (enableCSVUpload ? "block " : "hidden ") +
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                      ? "text-black dark:text-white dark:bg-slate-800 bg-slate-200"
                      : "text-" + color + "-600 bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  <i className="fas fa-cog text-base mr-1"></i> CSV Upload
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words bg-white dark:bg-gray-900 w-full mb-6 shadow-lg rounded">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <form className="w-full " onSubmit={handleSubmit}>
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-gray-400">
                            First Name
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-first-name"
                            type="text"
                            placeholder="Jane"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                          />
                          {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-gray-400">
                            Last Name
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="Doe"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-gray-400">
                            Email
                          </label>
                          <input
                            className="content-start	 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-email"
                            type="text"
                            placeholder="jane.doe@microsoft.com"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          {/* <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-gray-400">
                            Company
                          </label>
                          <input
                            className="content-start	 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-email"
                            type="text"
                            placeholder="Microsoft"
                            onChange={(e) => setCompany(e.target.value)}
                            value={company}
                          />
                          {/* <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
                        </div>
                      </div>
                      <div className="flex flex-wrap space-x-4 -mx-3 mb-6">
                        {/* <div className="md:w-1/3"></div> */}
                        {/* <div className="md:w-3/3"> */}
                        <button
                          className="shadow bg-lime-700 hover:bg-lime-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 ml-3 px-4 rounded"
                          type="submit"
                        >
                          Search
                        </button>
                        {/* </div> */}
                        {spinner && <LoadingIcons.Puff stroke="#1ab80c" />}
                      </div>

                      <div className="block tracking-wide text-black text-xs font-bold mb-20 dark:text-gray-400 mb-20">
                        {message ? (
                          <p className="text-black dark:text-gray-400">
                            {" "}
                            {message}
                          </p>
                        ) : null}

                        <div className="flex justify-end -mx-3 mb-6">
                          {jsonRes ? (
                            <div>
                              <button
                                type="button"
                                className="shadow bg-blue-700 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 mr-3 px-4 rounded"
                                onClick={getCsv}
                              >
                                Download CSV
                              </button>
                              <CSVLink
                                data={transactionData}
                                filename="transactions.csv"
                                className="hidden"
                                ref={csvLink}
                                target="_blank"
                              />
                            </div>
                          ) : null}
                        </div>

                        {jsonRes ? (
                          // <pre className="text-black dark:text-gray-400">{ JSON.stringify(jsonRes, null, 2)}</pre> : null}
                          <JsonViewer
                            value={jsonRes}
                            theme={ocean}
                            displayDataTypes={false}
                            quotesOnKeys={false}
                          />
                        ) : null}
                      </div>
                    </form>
                  </div>
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <p className="text-black dark:text-gray-400">
                      Bulk upload a CSV file to quickly find enriched contacts.
                      Max of 100 rows at a time.
                      <br />
                      First Name and Last Name are required.
                      <br />
                      Either email or company name is also required.
                      <br />
                      <br />
                    </p>
                    <Importer
                      dataHandler={async (rows) => {
                        // required, receives a list of parsed objects based on defined fields and user column mapping;
                        // may be called several times if file is large
                        // (if this callback returns a promise, the widget will wait for it before parsing more data)
                        // setBulkMessage();

                        console.log("received batch of rows", rows);
                        for (let row of rows) {
                          csvStorage.push(row);
                        }
                        // mock timeout to simulate processing
                        await new Promise((resolve) =>
                          setTimeout(resolve, 500)
                        );
                      }}
                      chunkSize={10000} // optional, internal parsing chunk size in bytes
                      defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                      restartable={true} // optional, lets user choose to upload another file when import is complete
                      onStart={({ file, fields }) => {
                        // optional, invoked when user has mapped columns and started import
                        console.log(
                          "starting import of file",
                          file,
                          "with fields",
                          fields
                        );

                        // setJsonBulk("");
                        // setJsonBulk();
                      }}
                      onComplete={({ file, fields }) => {
                        // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                        console.log(
                          "finished import of file",
                          file,
                          "with fields",
                          fields
                        );
                        console.log(csvStorage);
                        console.log("length of csv storage is " + csvStorage.length)
                        if (csvStorage.length < 100){
                          setBulkMessage("");
                          bulkEnrich(csvStorage);
                          csvStorage = [];

                        }
                        else {

                          toast.error('A max of 100 contacts is supported at once. Please split your CSV into multiple \
                          files with less than 100 rows contained in each.', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            });

                          setBulkMessage("");
                          csvStorage = [];

                        }
                 
                      }}
                      // onClose={() => {
                      //   // optional, invoked when import is done and user clicked "Finish"
                      //   // (if this is not specified, the widget lets the user upload another file)
                      //   console.log("importer dismissed");
                      // }}
                    >
                      <ImporterField name="first_name" label="first_name" />
                      <ImporterField name="last_name" label="last_name" />
                      <ImporterField name="email" label="email" optional />
                      <ImporterField name="company" label="company" optional />
                    </Importer>
                    <br />
                    {spinner && <LoadingIcons.Puff stroke="#1ab80c" />}

                    <div className="block tracking-wide text-black text-xs font-bold mb-20 dark:text-gray-400 mb-5">
                      {bulkMessage ? (
                        <p className="text-black dark:text-gray-400">
                          {" "}
                          {bulkMessage}
                        </p>
                      ) : null}

                      {jsonResBulk ? (
                        <div className="flex justify-end -mx-3 mb-6">
                          <button
                            type="button"
                            className="shadow bg-blue-700 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 mr-3 px-4 rounded"
                            onClick={getCsvBulk}
                          >
                            Download CSV
                          </button>
                          <CSVLink
                            data={bulkData}
                            filename="contacts.csv"
                            className="hidden"
                            ref={bulkCsvLink}
                            target="_blank"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      </div>
    </>
  );
}
