import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import { generateAvatar } from "../../utils/GenerateAvatar";
import { TfiReload } from 'react-icons/tfi';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import auth from "../../config/firebase";

import {getUser} from "../../utils/UserLookup"; 
import { PatternFormat } from 'react-number-format';


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState();
  const [loading, setLoading] = useState(false);

  const { currentUser, updateUserProfile, setError } = useAuth();
  const profileInfo = {}

  function ParsePhoneNumber(phoneNumber) {

    if (!phoneNumber){
      return ''
    }
    let finalPhoneNumber = '';
    if (phoneNumber.length == 11 && phoneNumber[0] == '1'){

      finalPhoneNumber = phoneNumber.substring(1)
      console.log(finalPhoneNumber)
      return finalPhoneNumber;

    }
    return phoneNumber;
  }

  useEffect(() => {
    const fetchData = () => {
      const res = generateAvatar();
      setAvatars(res);
    };

    
    const getUsers = async () => {
      var userJson = {};
      await getUser().then(result => {
        userJson = result
      });
      if (userJson.status === "200"){
        setFirstName(userJson.firstName)
        setLastName(userJson.lastName)
        // console.log("phone number is " + userJson.phoneNumber)
        setPhoneNumber(ParsePhoneNumber(userJson.phoneNumber))
      }
    }

    fetchData();
    getUsers();
    
  }, []);

  const handleFormSubmit = async (e) => {

    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
    e.preventDefault();

    if (selectedAvatar === undefined) {
      toast.error('Error: Please select an avatar', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      return setError("Please select an avatar");
    }

    try {
      setError("");
      setLoading(true);
      // const user = currentUser;
      const profile = {
        displayName: username,
        photoURL: avatars[selectedAvatar],
      };
      await updateUserProfile(user, profile);


      await fetch(process.env.REACT_APP_API_HOST + "/api/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
        }),
      });
      navigate("/");
    } catch (e) {
      setError("Failed to update profile");
    }

    setLoading(false);
  };

  const handleRefresh = async (e) => {
    e.preventDefault();
    const res = generateAvatar();
    setAvatars(res);

  };
  const handleExit = async (e) => {
    e.preventDefault();
    navigate("/")

  };

  return (
    <>
    <ToastContainer
    position="bottom-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
  />
    <div className="justify-center flex items-center w-full py-6 px-4 sm:px-6 lg:px-8">

    {/* <div className="min-h-full flex items-center justify-center pb-20 py-12 px-4 sm:px-6 lg:px-8"> */}
      <div className="max-w-md space-y-8">
      {/* <button onClick={handleExit} type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
          <span className="sr-only">Icon description</span>
          </button> */}
        <div className="text-center">

        <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
            Update Your Profile
          </h2>
          <h2 className="mt-4 text-1xl text-center tracking-tight font-light dark:text-white">
            Pick an avatar
          </h2>
        </div>
        <form className="space-y-6" onSubmit={handleFormSubmit}>
          <div className="flex flex-wrap -m-1 md:-m-2">
            {avatars.map((avatar, index) => (
              <div key={index} className="flex flex-wrap w-1/3">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt="gallery"
                    className={classNames(
                      index === selectedAvatar
                        ? "border-4  border-blue-700 dark:border-blue-700"
                        : "cursor-pointer hover:border-4 hover:border-blue-700",
                      "block object-cover object-center w-18 h-18 rounded-full"
                    )}
                    src={avatar}
                    onClick={() => setSelectedAvatar(index)}
                  />
                </div>
              </div>
            ))}
   
          </div>
          <div className="text-center -m-10 -md:p-5">

          <button onClick={handleRefresh} type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>  
          <span className="sr-only">Icon description</span>
          </button>



</div>
          <div className="rounded-md  -space-y-px">
            {/* <input
              id="username"
              name="username"
              type="text"
              autoComplete="username"
              required
              className="appearance-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Enter a Display Name"
              defaultValue={currentUser.displayName && currentUser.displayName}
              onChange={(e) => setUsername(e.target.value)}
            />
            <br/> */}
            <input
              id="firstName"
              name="firstName"
              type="text"
              autoComplete="firstname"
              required
              className="appearance-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="First Name"
              defaultValue={firstName}
              onChange={(e) => setFirstName(e.target.value)}        
              />
                        <br/>
            <input
              id="lastName"
              name="lastName"
              type="text"
              autoComplete="lastname"
              required
              className="appearance-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Last Name"
              defaultValue={lastName}
              onChange={(e) => setLastName(e.target.value)} 
            />
                                    <br/>

            <PatternFormat 
              format="+1 (###) ### ####" 
              // allowEmptyFormatting 
              // required
              placeholder="Phone Number (Optional)"
              mask="_" 
              value={(phoneNumber ? phoneNumber : '')}
              valueIsNumericString={true}
              // onValueChange={e => setPhoneNumber(e.formattedValue)}
              onValueChange={(values, sourceInfo) => {
                setPhoneNumber(values.value)
              }}
              className="appearance-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
            />

            <p id="helper-text-explanation" className="mt-2 text-sm py-1 text-gray-500 dark:text-gray-400">Only US phone numbers are supported at this time.</p>


            {/* <input
              id="phoneNumber"
              name="phonenumber"
              type="tel"
              autoComplete="phonenumber"
              required
              className="appearance-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Phone Number"
              defaultValue={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)} 
            /> */}
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Update Profile
            </button>
            <br/>
            <br/>
            <button
              type="button"
              disabled={loading}
              onClick={handleExit}
              className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
}
