import { LogoutIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { Disclosure } from '@headlessui/react';

import { useAuth } from "../../contexts/AuthContext";
import Logout from "../accounts/Logout";
import ThemeToggler from "./ThemeToggler";
import auth from "../../config/firebase";

// ICONS
import * as FaIcons from "react-icons/fa"; //Now i get access to all the icons
import * as AiIcons from "react-icons/ai";

import { IconContext } from "react-icons";

// ROUTING

import { Link } from "react-router-dom";

// DATA FILE
export default function FAQ() {
  const [modal, setModal] = useState(false);

  const { currentUser } = useAuth();

  const faqs = [
    // {
    //   id: 1,
    //   question: 'What is your refund policy?',
    //   answer: `If you're unhappy with your purchase for any reason, email us within 90 days and we'll refund you in full, no questions asked.`
    // },
    // {
    //   id: 1,
    //   question: 'How long does it take to get my order?',
    //   answer: `Most customers can expect to receive their food and supplies within 1 to 3 days. Orders that require prescription approval or personalization may take longer to ship.`
    // },
    // {
    //   id: 1,
    //   question: 'Do you offer technical support?',
    //   answer: `No.`
    // },
    // {
    //   id: 1,
    //   question: 'How much is shipping?',
    //   answer: `Orders over $49 ship free! All other orders ship for a flat rate of $4.95.`
    // },
    {
      id: 1,
      question: 'How do I contact support?',
      answer: `We offer support through email. Please email support@getmobly.com`
    }
  ]

  return (
    <>


<div className="min-h-screen flex justify-center px-4 sm:px-6 lg:px-8">

<div className="container mx-auto">
  <div className="flex flex-wrap justify-center">
    <div className="w-3/4 text-gray-500 dark:text-gray-400">
      <br/>
      <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
        FAQ      
    </h2>
    <br/>
      {faqs.map((faq) => (
        <Disclosure>
          <div className={faq.id}>
            <Disclosure.Button className="flex w-full justify-between rounded-lg dark:bg-white bg-gray-600 px-4 py-2 mb-2 text-left font-medium text-white dark:text-gray-600">
              {faq.question}
            </Disclosure.Button>
            <Disclosure.Panel className="text-gray-500 dark:text-white flex w-full justify-between rounded-lg px-4 mb-2 text-left text-sm">
              {faq.answer}
            </Disclosure.Panel>
          </div>
        </Disclosure>
        ))}
<br/>

 </div>
</div>
</div>
</div>


  </>
  );
}
