import { LogoutIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";

import { useAuth } from "../../contexts/AuthContext";
import Logout from "../accounts/Logout";
import ThemeToggler from "./ThemeToggler";
import auth from "../../config/firebase";

// ICONS
import * as FaIcons from "react-icons/fa"; //Now i get access to all the icons
import * as AiIcons from "react-icons/ai";

import { IconContext } from "react-icons";

// ROUTING

import { Link } from "react-router-dom";

// DATA FILE
export default function Privacy() {
  const [modal, setModal] = useState(false);

  const { currentUser } = useAuth();



  return (
    <>


<div className="min-h-screen flex justify-center px-4 sm:px-6 lg:px-8">

<div className="container mx-auto">
  <div className="flex flex-wrap justify-center">
    <div className="w-3/4 text-gray-500 dark:text-gray-400">
      <br/>
      <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
      Privacy Policy for Mobly          
      </h2>
<br/>

<p>Effective Date: 3/7/2023.</p>
<br/>

<p>At Mobly, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share information about you when you use our mobile application and related services (collectively, the &quot;Services&quot;). By using the Services, you agree to the terms of this Privacy Policy.Information We CollectWe collect various types of information when you use our Services. The types of information we collect include:</p>
<br/>
<p>Personal Information: We may collect your name, email address, phone number, job title, and other contact information when you register for our Services or communicate with us. We may also collect your payment information, such as your credit card number, when you purchase our Services.Usage Information: We may collect information about how you use our Services, including the pages you view, the links you click, and other actions you take in connection with the Services.</p>
<br/>

<p>Device Information: We may collect information about the device you use to access our Services, including the type of device, its operating system, and other technical information.Location Information: We may collect information about your location when you use our Services.</p>
<br/>

<p>Other Information: We may collect other information that you choose to provide to us, such as when you fill out a form, participate in a survey, or contact us for customer support.How We Use Your InformationWe use the information we collect to provide, maintain, and improve our Services, as well as to communicate with you and personalize your experience. We may use your information for the following purposes:To provide our Services, including processing your transactions and delivering content to you;To personalize your experience, such as by providing you with relevant content and recommendations;To communicate with you about our Services, including responding to your inquiries and providing customer support;To improve our Services, such as by analyzing usage trends and measuring the effectiveness of our marketing campaigns;To comply with legal obligations, such as responding to lawful requests and court orders;To protect our rights and the rights of others, such as by detecting and preventing fraud and other illegal activities.</p>
<br/>

<p>How We Share Your Information: We may share your information with third parties in the following circumstances:</p>
<br/>

<p>With Service Providers: We may share your information with third-party service providers who help us provide, maintain, and improve our Services, such as payment processors and data analytics providers.</p>
<br/>

<p>With Affiliates: We may share your information with our affiliates, such as subsidiaries, joint ventures, or other companies that we own or control.With Business Partners: We may share your information with our business partners, such as advertisers and sponsors, in connection with promotions or other initiatives.</p>
<br/>

<p>With Other Users: We may share your information with other users of the Services, such as when you participate in a discussion forum or social media platform, or when they scan your contact.</p>
<br/>

<p>With Law Enforcement: We may share your information with law enforcement agencies or other government bodies when we believe it is necessary to comply with legal obligations or to protect our rights or the rights of others.</p>
<br/>

<p>With Your Consent: We may share your information with third parties with your consent.</p>
<br/>
 
    </div>
</div>
</div>
</div>


  </>
  );
}
