import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from "./Footer";

const Layout = ({ children }) => {
    return (
        <>
            <div className='flex min-h-screen'>
                <Sidebar />
                <div className='grow'>
                    <Header />
                    <div className='m-5'>{children}</div>
                    <Footer />

                </div>
            </div>
        </>
    )
}

export default Layout