import React from "react";
import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
import LoadingIcons from 'react-loading-icons'
import { Link, useNavigate } from "react-router-dom";

import { FilePond, registerPlugin } from "react-filepond"
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import { useAuth } from "../../contexts/AuthContext";


import auth from "../../config/firebase";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {getUser} from "../../utils/UserLookup"; 

// FilePond.registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);


export default function CsvLayout() {
  const { currentUser, updateUserProfile, setError } = useAuth();

  const [spinner, setSpinner] = useState(false);    
  const [submitButton, setSubmitButton] = useState(false);    
  const [foundProfile, setFoundProfile] = useState(true);    

  const [csvCollection, setCsvCollection] = useState([]);

  useEffect(() => {
    setError("");

    const getUsers = async () => {
        var userJson = {};
        await getUser().then(result => {
          userJson = result
        });
        // console.log(userJson)
        if (userJson.status === "200"){
          setFoundProfile(true)
        }
        else {
          setFoundProfile(false)
        }
      }
      getUsers();

    
  }, []);


 
  const onFileChange = (files) => {
    setSubmitButton(true)

    const items = files.map((fileItem) => fileItem.file);
    setCsvCollection([...csvCollection, items]);
  };

  const onSubmit = async (e) => {

    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
      e.preventDefault()
      setSpinner(true)


      var formData = new FormData()

      for (let csv in csvCollection[0]) {
          formData.append('files', csvCollection[0][csv])
      }

      try {

        let res = await fetch(process.env.REACT_APP_API_HOST + "/api/file", {
          method: "POST",
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        // console.log("response from bulk api was: ");
        // console.log(JSON.stringify(resJson));
      if (res.status === 200) {
        setCsvCollection([]);
        // csvChanged(res);
  
  
        setSpinner(false)
  
        toast.success('Successfully submitted CSV! We\'ll email you within 24 hours with your enriched list.', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        
      }
      else {
        toast.error('There was an error uploading your file. Please email support at support@getmobly.com', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
  
      }



      }

      catch (err){
        console.log(err);
      }
     



      // axios.post(`${process.env.REACT_APP_API_ENDPOINT}/`, formData, {
      // }).then(res => {
      //     console.log(res.data)
      // })
  }



    return (
    <>
      <div>
  
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      </div>
      <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
Lead Enrichment          </h2>
      <div className="justify-center px-4 sm:px-6 lg:px-8 duration-300 ">

      <div className="container mx-auto">
        <div className="flex flex-wrap justify-center">
          <div className="w-3/4">
            <br/>
          <div className="rounded-md bg-blue-100 border border-blue-500 text-blue-700 px-4 py-3" role="alert">
            <p className="font-bold">Submit your leads to be enriched</p>
            <p className="text-xs">Required fields are First Name, Last Name, and Company or Email.</p>
            <p className="text-xs">Here is an example CSV <Link to="/example.csv" target="_blank" className="underline" download>template</Link>.</p>
            <p className="text-xs">Results will be emailed within 24 hours.</p>
          </div>
          {!foundProfile && (
            <>
          <br/>
          <div className="rounded-md bg-red-100 border border-red-500 text-red-700 px-4 py-3" role="alert">
            <p className="font-bold">You have not completed your profile</p>
            <p className="text-xs">Please update your  <a href="/profile" className="underline">profile</a> first before uploading your CSV file.</p>
          </div>
          </>
          )}
            <br/>

            <form onSubmit={onSubmit}>

                <div className="filepond-wrapper">
                    <FilePond 
                        acceptedFileTypes={['text/csv', '.csv']}
                        files={csvCollection}
                        allowMultiple={false}
                        // maxFiles={3}
                        server={null}
                        instantUpload={false}
                        credits={false}
                        labelIdle='Drag & Drop your CSV file or <span className="filepond--label-action">Browse</span>'
                        onupdatefiles={(fileItems) => onFileChange(fileItems)}
                        maxParallelUploads={1}
                      />
                </div>


                <div className="flex flex-wrap space-x-4 -mx-3 mb-6">
                      {/* <div className="md:w-1/3"></div> */}
                      {/* <div className="md:w-3/3"> */}
                      
                        <button disabled={submitButton && foundProfile ? false : true}className="shadow bg-lime-700 hover:bg-lime-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 ml-3 px-4 rounded disabled:opacity-30" type="submit">
                          Upload
                        </button>
                      {/* </div> */}
                        {spinner && (<LoadingIcons.Puff stroke="#1ab80c"/>)}

                        
                    </div>

            </form>
          </div>
        </div>
      </div>
      </div>
    </>

    );
  }