import { LogoutIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { Disclosure } from '@headlessui/react';


import { useAuth } from "../../contexts/AuthContext";
import Logout from "../accounts/Logout";
import ThemeToggler from "./ThemeToggler";
import auth from "../../config/firebase";

// ICONS
import * as FaIcons from "react-icons/fa"; //Now i get access to all the icons
import * as AiIcons from "react-icons/ai";

import { IconContext } from "react-icons";

// ROUTING

import { Link } from "react-router-dom";

// DATA FILE
export default function NotFound() {
  const [modal, setModal] = useState(false);

  const { currentUser } = useAuth();

  return (
    <>


<div className="min-h-screen flex justify-center px-4 sm:px-6 lg:px-8">

<div className="container mx-auto">
  <div className="flex flex-wrap justify-center">
    <div className="w-3/4 text-gray-500 dark:text-gray-400">
      <br/>
      <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
        404 - Not Found 
        {/* <Link to="/">Go Home</Link> */}
    </h2>

    <br/>
<br/>

 </div>
</div>
</div>
</div>


  </>
  );
}
