import { useAuth } from "../contexts/AuthContext";

import auth from "../config/firebase";



export const getUser = async () => {
    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
    let res = await fetch(process.env.REACT_APP_API_HOST + "/api/user", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    });
    return res.json()

  }