import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import auth from "../../config/firebase";
import LoadingIcons from "react-loading-icons";
import { JsonViewer } from "@textea/json-viewer";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import { Parser } from "@json2csv/plainjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import capitalize from "capitalize";
import {
  default as defaultFormatter,
  number as numberFormatter,
  string as stringFormatter,
  stringQuoteOnlyIfNecessary as stringQuoteOnlyIfNecessaryFormatter,
  stringExcel as stringExcelFormatter,
  symbol as symbolFormatter,
  object as objectFormatter,
} from "@json2csv/formatters";
import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";


export default function DBEnrichLayout() {
  // const [isContact, setIsContact] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [bulkMessage, setBulkMessage] = useState("");

  const [jsonRes, setJson] = useState("");
  const [jsonResBulk, setJsonBulk] = useState("");

  const [enableCSVUpload, setCSVUpload] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [openTab, setOpenTab] = useState("");
  const { color } = "slate";
  const { currentUser } = useAuth();
  const ocean = {
    scheme: "Ocean",
    base00: "#2b303b",
    base01: "#343d46",
    base02: "#4f5b66",
    base03: "#65737e",
    base04: "#a7adba",
    base05: "#c0c5ce",
    base06: "#dfe1e8",
    base07: "#eff1f5",
    base08: "#bf616a",
    base09: "#d08770",
    base0A: "#ebcb8b",
    base0B: "#a3be8c",
    base0C: "#96b5b4",
    base0D: "#8fa1b3",
    base0E: "#b48ead",
    base0F: "#ab7967",
  };

  // const jsonRes = ""
  const [transactionData, setTransactionData] = useState("");
  const [bulkData, setBulkData] = useState("");

  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
  const bulkCsvLink = useRef();
  const navigate = useNavigate();

  var csvStorage = [];

  useEffect(() => {
    const fetchUser = async () => {
      const user = auth.currentUser;
      const token = user && (await user.getIdToken());
      let res = await fetch(process.env.REACT_APP_API_HOST + "/api/user/role", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(tempJson),
      });

      if (res.status === 200) {
        let resJson = await res.json();
        return resJson;
      } else {
        return {};
      }
    };

    (async () => {
      const result = await fetchUser();
      console.log(result)
      if (JSON.stringify(result) === '{}'){

        navigate("/")

      }
      else if ("code" in result && result.code === "ADM"){

              setCSVUpload(true);

      }
      // setCSVUpload("code" in result && result.code == "ADM" ? true : false);
    })();

    setOpenTab(1);
  }, []);


  const getCsvBulk = async () => {
    // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
    bulkCsvLink.current.link.click();
  };

  const bulkEnrich = async (rows) => {
    setJsonBulk();

    var recordCounter = 0;
    try {
      console.log("rows are:");
      console.log(rows);
      let tempJson = {
        requests: [],
      };

      for (let i = 0; i < rows.length; i++) {
        let params = {
          params: {
            email: rows[i].email,
            first_name: rows[i].first_name,
            last_name: rows[i].last_name,
            company: rows[i].company,
            phone_number: rows[i].phone_number,
            linkedin: rows[i].linkedin,
            profile_picture: rows[i].profile_picture,
            facebook: rows[i].facebook,
            twitter: rows[i].twitter,
            job_position: rows[i].job_position,
            job_company_industry: rows[i].job_company_industry,
            job_company_linkedin: rows[i].job_company_linkedin,
            job_company_location_city: rows[i].job_company_location_city,
            job_company_location_state: rows[i].job_company_location_state,
            job_company_location_street_address: rows[i].job_company_location_street_address,
            job_company_zip_code: rows[i].job_company_zip_code,
          },
        };
        tempJson.requests.push(params);
      }

      console.log("temp json:");
      console.log(JSON.stringify(tempJson));
      setSpinner(true);

      const user = auth.currentUser;
      const token = user && (await user.getIdToken());
      let res = await fetch(
        process.env.REACT_APP_API_HOST + "/api/db/bulk",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(tempJson),
        }
      );
      let resJson = await res.json();
      console.log("response from bulk api was: ");
      console.log(JSON.stringify(resJson));
      if (res.status === 200) {
        // setSpinner(false);
        // console.log("res json length is: " + resJson.length);
        toast.info(
            resJson.Successful.toString() +
              " of " +
              resJson.Total.toString() +
              " rows were successfully enriched.",
            {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setBulkMessage(
            "Successfully enriched " +
            resJson.Successful.toString() +
              " of " +
              resJson.Total.toString() +
              " rows." + " " +
              "Successfully found or inserted " + resJson.Total.toString() +
              " of " +
              resJson.Total.toString() +
              " rows in the DB."
          );
          setSpinner(false);
       
      }
    } catch (err) {
      console.log(err);
    }
  };



  return (
    <>
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
      <div className="min-h-screen flex justify-center px-4 sm:px-6 lg:px-8">

      <div className="container mx-auto">
        <div className="flex flex-wrap justify-center">
          
        <div className="w-3/4 text-gray-500 dark:text-gray-400">
        <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
        DB Upload and Enrichment      
    </h2>
    <br/>
                    <p className="text-black dark:text-gray-400">
                      Bulk upload a CSV file to quickly find enriched contacts and insert all contacts into the DB.
                      Max of 100 rows at a time.
                      <br />
                      First Name and Last Name are required.
                      <br />
                      Either email or company name is also required.
                      No CSV file is returned since it's directly inserted in the DB.
                      <br />
            
                    </p>

                    <p className="text-black dark:text-gray-400">Here is an example CSV <Link to="/db_insert_example.csv" target="_blank" className="underline" download>template</Link>.</p>
                    <br />
                    <Importer
                      dataHandler={async (rows) => {
                        // required, receives a list of parsed objects based on defined fields and user column mapping;
                        // may be called several times if file is large
                        // (if this callback returns a promise, the widget will wait for it before parsing more data)
                        // setBulkMessage();

                        console.log("received batch of rows", rows);
                        for (let row of rows) {
                          csvStorage.push(row);
                        }
                        // mock timeout to simulate processing
                        await new Promise((resolve) =>
                          setTimeout(resolve, 500)
                        );
                      }}
                      chunkSize={10000} // optional, internal parsing chunk size in bytes
                      defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                      restartable={true} // optional, lets user choose to upload another file when import is complete
                      onStart={({ file, fields }) => {
                        // optional, invoked when user has mapped columns and started import
                        console.log(
                          "starting import of file",
                          file,
                          "with fields",
                          fields
                        );

                        // setJsonBulk("");
                        // setJsonBulk();
                      }}
                      onComplete={({ file, fields }) => {
                        // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                        console.log(
                          "finished import of file",
                          file,
                          "with fields",
                          fields
                        );
                        console.log(csvStorage);
                        console.log("length of csv storage is " + csvStorage.length)
                        if (csvStorage.length < 100){
                          setBulkMessage("");
                          bulkEnrich(csvStorage);
                          csvStorage = [];

                        }
                        else {

                          toast.error('A max of 100 contact rows is supported at once. Please split your CSV into multiple \
                          files with less than 100 rows contained in each.', {
                            position: "bottom-right",
                            autoClose: 7000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            });

                          setBulkMessage("");
                          csvStorage = [];

                        }
                 
                      }}
                      // onClose={() => {
                      //   // optional, invoked when import is done and user clicked "Finish"
                      //   // (if this is not specified, the widget lets the user upload another file)
                      //   console.log("importer dismissed");
                      // }}
                    >
                      <ImporterField name="first_name" label="first_name" />
                      <ImporterField name="last_name" label="last_name" />
                      <ImporterField name="email" label="email" optional />
                      <ImporterField name="company" label="company" optional />
                      <ImporterField name="phone_number" label="phone_number" optional />
                      <ImporterField name="linkedin" label="linkedin" optional />
                      <ImporterField name="profile_picture" label="profile_picture" optional />
                      <ImporterField name="facebook" label="facebook" optional />
                      <ImporterField name="twitter" label="twitter" optional />
                      <ImporterField name="job_position" label="job_title" optional />
                      <ImporterField name="job_company_industry" label="job_company_industry" optional />
                      <ImporterField name="job_company_linkedin" label="job_company_linkedin" optional />
                      <ImporterField name="job_company_location_city" label="job_company_location_city" optional />
                      <ImporterField name="job_company_location_state" label="job_company_location_state" optional />
                      <ImporterField name="job_company_location_street_address" label="job_company_location_street_address" optional />
                      <ImporterField name="job_company_zip_code" label="job_company_zip_code" optional />

                    </Importer>
                    <br />
                    {spinner && <LoadingIcons.Puff stroke="#1ab80c" />}

                    <div className="block tracking-wide text-black text-xs font-bold mb-20 dark:text-gray-400 mb-5">
                      {bulkMessage ? (
                        <p className="text-black dark:text-gray-400">
                          {" "}
                          {bulkMessage}
                        </p>
                      ) : null}

                    </div>
                  </div>
                </div>
              </div>
              </div>


    </>
  );
}
