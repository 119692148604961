import { LogoutIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";

import { useAuth } from "../../contexts/AuthContext";
import Logout from "../accounts/Logout";
import ThemeToggler from "./ThemeToggler";
import auth from "../../config/firebase";

// ICONS
import * as FaIcons from "react-icons/fa"; //Now i get access to all the icons
import * as AiIcons from "react-icons/ai";

import { IconContext } from "react-icons";

// ROUTING

import { Link } from "react-router-dom";

// DATA FILE
export default function Footer() {
  const [modal, setModal] = useState(false);

  const { currentUser } = useAuth();



  return (
    <>
{/* <footer className="absolute bottom-0 flex justify-start bg-white rounded-lg shadow m-4 dark:bg-gray-800"> */}

<footer className="duration-300 sticky top-[100vh] left-0 z-10 w-full p-4 bg-gray-100 md:flex md:items-center md:justify-between md:p-0 dark:bg-gray-800 dark:border-gray-600">

    <div className="w-full mx-auto container md:p-6 p-4 md:flex md:items-center md:justify-between">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://getmobly.com/" className="hover:underline">Mobly</a>. All Rights Reserved.
    </span>
    <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
            <a href="https://www.getmobly.com/enrich-my-leads" className="mr-4 hover:underline md:mr-6 ">About</a>
        </li>
        <li>
            <a href="/privacy" className="mr-4 hover:underline md:mr-6">Privacy Policy</a>
        </li>
        <li>
            <a href="/faq" className="mr-4 hover:underline md:mr-6">FAQ</a>
        </li>
        <li>
            <a href="mailto:support@getmobly.com" className="hover:underline">Support</a>
        </li>
    </ul>
    </div>
</footer>

      {modal && <Logout modal={modal} setModal={setModal} />}

  </>
  );
}
