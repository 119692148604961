import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";

import { AuthProvider } from "./contexts/AuthContext";
import Register from "./components/accounts/Register";
import Login from "./components/accounts/Login";
import Profile from "./components/accounts/Profile";
import WithPrivateRoute from "./utils/WithPrivateRoute";
import EnrichLayout from "./components/layouts/EnrichLayout";
import DBEnrichLayout from "./components/layouts/DBEnrichLayout";

import Header from "./components/layouts/Header";
import ErrorMessage from "./components/layouts/ErrorMessage";
import CsvLayout from "./components/layouts/CsvLayout";
import Footer from "./components/layouts/Footer";
import Privacy from "./components/layouts/Privacy";
import FAQ from "./components/layouts/FAQ";
import NotFound from "./components/layouts/NotFound";
import ResetPassword from "./components/accounts/ResetPassword";
import Sidebar from './components/layouts/Sidebar'
import Layout from './components/layouts/Layout'


function App() {
  return (
    <AuthProvider>
      <Router>
        {/* <Header /> */}
        <ErrorMessage />
        <Layout>
        <Routes>
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/resetpwd" element={<ResetPassword />} />
          <Route
            exact
            path="/profile"
            element={
              <WithPrivateRoute>
                <Profile />
              </WithPrivateRoute>
            }
          />
          <Route
            exact
            path="/"
            element={
              <WithPrivateRoute>
                <CsvLayout />
              </WithPrivateRoute>
            }
          />
          <Route
            exact
            path="/csv"
            element={
              <WithPrivateRoute>
                <EnrichLayout />
              </WithPrivateRoute>
            }
          />
          <Route
            exact
            path="/csvdb"
            element={
              <WithPrivateRoute>
                <DBEnrichLayout />
              </WithPrivateRoute>
            }
          />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        </Layout>
      </Router>
      {/* <Footer /> */}
    </AuthProvider>
  );
}

export default App;
