import Reat, { useEffect, useRef, useState } from "react";

import { Link, useLocation } from 'react-router-dom'
import auth from "../../config/firebase";
import { useAuth } from "../../contexts/AuthContext";

import { BsArrowLeftCircle } from 'react-icons/bs'
import { AiFillPieChart } from 'react-icons/ai'
import { SiFuturelearn } from 'react-icons/si'
import { SiOpenaccess } from 'react-icons/si'
import { SiGooglesheets, SiMicrosoftexcel, SiFiles, SiAmazondynamodb } from 'react-icons/si'

import { CgProfile } from 'react-icons/cg'
// import Logo from '../assets/images/logo.svg'
import HamburgerButton from '../hamburgerMenu/HamburgerButton'

const Sidebar = () => {

  const { currentUser, updateUserProfile, setError } = useAuth();

  const [open, setOpen] = useState(true)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [adminRole, setAdminRole] = useState(false)
  const location = useLocation()
  const user = auth.currentUser;
  var Menus = []
  var AdminMenus = []
  var SettingsMenus = []

  useEffect(() => {
    const fetchUser = async () => {
      const user = auth.currentUser;
      const token = user && (await user.getIdToken());
      let res = await fetch(process.env.REACT_APP_API_HOST + "/api/user/role", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(tempJson),
      });

      if (res.status == 200) {
        let resJson = await res.json();
        return resJson;
      } else {
        return {};
      }
    };

    (async () => {
      const result = await fetchUser();
      // console.log(result)

      if ("code" in result && result.code == "ADM"){

        // [...csvCollection, items]
        setAdminRole(true)

      }
    })();

  }, []);


  if (user){

    Menus = [
      { title: 'Enrichment', path: '/', src: <SiFiles /> },
      // { title: 'Course', path: '/course', src: <SiFuturelearn /> },
      // { title: 'Settings', path: '/profile', src: <CgProfile /> },
      // { title: 'Log Out', path: '/logout', src: <SiOpenaccess />, gap: 'true' }
    ]
    SettingsMenus = [
      { title: 'Settings', path: '/profile', src: <CgProfile /> },
    ]


    AdminMenus = [

      { title: 'CSV Enrichment', path: '/csv', src: <SiGooglesheets /> },
      { title: 'CSV to DB', path: '/csvdb', src: <SiAmazondynamodb /> },

    ]

  }
  else {

    Menus = [
      // { title: 'Enrichment', path: '/', src: <AiFillPieChart /> },
      // { title: 'Course', path: '/course', src: <SiFuturelearn /> },
      // { title: 'Profile', path: '/profile', src: <CgProfile /> },
      { title: 'Sign In', path: '/login', src: <SiOpenaccess />, gap: 'true' }
    ]

  }



  // useEffect(() => {
  //   const fetchData = () => {
  //     const res = generateAvatar();
  //     setAvatars(res);
  //   };
if (user){
  return (

    <>
      
      <div
        className={`${
          open ? 'w-60' : 'w-fit'
        } hidden sm:block relative flex duration-300 border-gray-200 bg-gray-100 dark:bg-gray-800 dark:border-gray-700 p-5 dark:bg-slate-800`}
      >
        <BsArrowLeftCircle
          className={`${
            !open && 'rotate-180'
          } absolute text-3xl bg-white fill-slate-800  rounded-full cursor-pointer top-9 -right-4 dark:fill-gray-400 dark:bg-gray-800`}
          onClick={() => setOpen(!open)}
        />
        <Link to='/'>
          <div className={`flex ${open && 'gap-x-4'} items-center`}>
            {/* <img src={Logo} alt='' className='pl-2' /> */}
            {open && (

<span className='text-xl font-medium whitespace-nowrap dark:text-white'>

{/* <Link to="/" className="flex"> */}
<img
          className="h-12 "
          src="https://uploads-ssl.webflow.com/636b097d285d5e6e555fc9da/638e5a4e899bab5d7dea0aa3_Mobly%20Logos%20and%20Submarks-26-p-500.png"
          alt=""
        />

{/* </Link> */}
              {/* <span className='text-xl font-medium whitespace-nowrap dark:text-white'> */}
              </span>
            )}
          </div>
        </Link>

        <ul className='pt-6'>
          {Menus.map((menu, index) => (
            <Link to={menu.path} key={index}>
              <li
                className={`flex items-center gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700
                        ${menu.gap ? 'mt-9' : 'mt-2'} ${
                  location.pathname === menu.path &&
                  'bg-gray-200 dark:bg-gray-700'
                }`}
              >
                <span className='text-2xl'>{menu.src}</span>
                <span
                  className={`${
                    !open && 'hidden'
                  } origin-left duration-300 hover:block`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>

            
          ))}


                    {AdminMenus.map((menu, index) => (
                      <Link to={menu.path} key={index}>
                        <li
                          className={`${!adminRole? 'hidden':'' } flex items-center gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700
                                  ${menu.gap ? 'mt-9' : 'mt-2'} ${
                            location.pathname === menu.path &&
                            'bg-gray-200 dark:bg-gray-700'
                          }`}
                        >
                          <span className='text-2xl'>{menu.src}</span>
                          <span
                            className={`${
                              !open && 'hidden'
                            } origin-left duration-300 hover:block`}
                          >
                            {menu.title}
                          </span>
                        </li>
                      </Link>
          
                      
                    ))}
                                        {SettingsMenus.map((menu, index) => (
            <Link to={menu.path} key={index}>
              <li
                className={`flex items-center gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700
                        ${menu.gap ? 'mt-9' : 'mt-2'} ${
                  location.pathname === menu.path &&
                  'bg-gray-200 dark:bg-gray-700'
                }`}
              >
                <span className='text-2xl'>{menu.src}</span>
                <span
                  className={`${
                    !open && 'hidden'
                  } origin-left duration-300 hover:block`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>

            
          ))}
        </ul>
      </div>
      {/* Mobile Menu */}
      <div className="pt-3">
        <HamburgerButton
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
        />
      </div>
      <div className="sm:hidden">
        <div
          className={`${
            mobileMenu ? 'flex' : 'hidden'
          } absolute z-50 flex-col items-center self-end py-8 mt-16 space-y-6 font-bold sm:w-auto left-6 right-6 dark:text-white  bg-gray-50 dark:bg-slate-800 drop-shadow md rounded-xl`}
        >
          {Menus.map((menu, index) => (
            <Link
              to={menu.path}
              key={index}
              onClick={() => setMobileMenu(false)}
            >
              <span
                className={` ${
                  location.pathname === menu.path &&
                  'bg-gray-200 dark:bg-gray-700'
                } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
              >
                {menu.title}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </>
    
  )

}

}

export default Sidebar