import { LogoutIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";

import { useAuth } from "../../contexts/AuthContext";
import Logout from "../accounts/Logout";
import ThemeToggler from "./ThemeToggler";
import auth from "../../config/firebase";

// ICONS
import * as FaIcons from "react-icons/fa"; //Now i get access to all the icons
import * as AiIcons from "react-icons/ai";

import { IconContext } from "react-icons";

// ROUTING

import { Link } from "react-router-dom";

// DATA FILE
export default function Header() {
  const [modal, setModal] = useState(false);

  const { currentUser } = useAuth();



  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await fetch("http://localhost:3001");
  //       console.log(await res.text());
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //   fetchData();
  // }, []);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const user = auth.currentUser;
//         const token = user && (await user.getIdToken());
//         console.log("token is " + token)
//         const payloadHeader = {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         };
//         const res = await fetch("http://localhost:3001", payloadHeader);
//         console.log(await res.text());
//       } catch (e) {
//         console.log(e);
//       }
//     };

//     fetchData();
// }, []);


  return (
    <>
      <nav className="duration-300 px- px-2 sm:px-4 py-2.5 bg-gray-100 border-gray-200 dark:bg-gray-800 text-gray-900 text-sm dark:text-white">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <Link to="/" className="flex">
            {!currentUser && <img
                    className="h-6 self-center"
                    src="https://uploads-ssl.webflow.com/636b097d285d5e6e555fc9da/638e5a4e899bab5d7dea0aa3_Mobly%20Logos%20and%20Submarks-26-p-500.png"
                    alt=""
                  />}

            <span className="font-family:Georgia px-2 self-center text-xl hidden sm:block font-semibold whitespace-nowrap text-gray-900 dark:text-white">
              Enrich My Leads
 
            </span>
  
          </Link>
          <div className="flex md:order-2">
            <ThemeToggler />

            {currentUser && (
              <>
                <button
                  className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none rounded-lg text-sm p-2.5"
                  onClick={() => setModal(true)}
                >
                  <LogoutIcon className="h-8 w-8" aria-hidden="true" />
                </button>

                <Link
                  to="/profile"
                  className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none rounded-full text-sm p-2.5"
                >
                  <img
                    className="h-8 w-8 rounded-full"
                    src={currentUser.photoURL}
                    alt=""
                  />
                </Link>
              </>
            )}
          </div>
        </div>
        
      </nav>
      {modal && <Logout modal={modal} setModal={setModal} />}

  </>
  );
}
